import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, ScrollView, TouchableOpacity, TextInput, SafeAreaView, Platform } from 'react-native';
import { Picker } from '@react-native-picker/picker';
import { printAsync } from 'expo-print';
import Base64ImageComponent from '../assets/Base64ImageComponent';
import DateTimePicker from '@react-native-community/datetimepicker';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const MakeInvoice = ({ navigation, route }) => {
  const { selectedItems = [], customerData } = route.params;
  const [goldCarat, setGoldCarat] = useState("750");
  const [initialTotalCarats, setInitialTotalCarats] = useState({});

  const [stoneWeights, setStoneWeights] = useState({});
  const [goldWeights, setGoldWeights] = useState({});
  const [totalCarats, setTotalCarats] = useState({});
  const [invoiceNo, setInvoiceNo] = useState(""); // State for Invoice No.
  const [dueDate, setDueDate] = useState(new Date()); // State for Due Date
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("Cash"); // State for selected payment method
  const [invoiceDate, setInvoiceDate] = useState(new Date()); // State for Invoice Date
  const [vatPercentage, setVatPercentage] = useState("21"); // State for VAT Percentage
  const [itemsWithNewPrices, setItemsWithNewPrices] = useState([]); // State for items with new prices

  useEffect(() => {
    const calculateTotalCaratWeights = () => {
      const caratWeights = {};
      selectedItems.forEach(item => {
        const weightCentralStone = parseFloat(item.attributes.find(attr => attr.slug === 'pa_weight-central-stone')?.options[0] || '0');
        const weightPaveStones = parseFloat(item.attributes.find(attr => attr.slug === 'pa_pave-diamonds-weight')?.options[0] || '0');
        const weightSideStones = parseFloat(item.attributes.find(attr => attr.slug === 'pa_weight-side-stones')?.options[0] || '0');
        const looseDiamondWeight = parseFloat(item.attributes.find(attr => attr.slug === 'pa_diamond-carat')?.options[0] || '0');
  
        const totalCarat = weightCentralStone + weightPaveStones + weightSideStones + looseDiamondWeight;
        caratWeights[item.id] = totalCarat.toFixed(2);
      });
      setInitialTotalCarats(caratWeights); // Set initial total carats
      setTotalCarats(caratWeights);
    };
  
    calculateTotalCaratWeights();
    updatePrices();
  }, [selectedItems, vatPercentage]);
  

  useEffect(() => {
    const updatedCarats = { ...totalCarats };
    for (const id in stoneWeights) {
      updatedCarats[id] = stoneWeights[id];
    }
    setTotalCarats(updatedCarats);
  }, [stoneWeights]);

  const updatePrices = () => {
    const vatRate = parseFloat(vatPercentage) / 100;

    const itemsWithUpdatedPrices = selectedItems.map(item => {
      const priceExcludingVAT = parseFloat(item.price) / 1.21; // assuming original VAT is 21%
      const newPrice = priceExcludingVAT * (1 + vatRate);
      return { ...item, newPrice: newPrice.toFixed(2) };
    });

    setItemsWithNewPrices(itemsWithUpdatedPrices);
  };

  const handleStoneWeightChange = (productId, weight) => {
    setStoneWeights(prevState => ({
      ...prevState,
      [productId]: weight
    }));
  };

  const handleGoldWeightChange = (productId, weight) => {
    setGoldWeights(prevState => ({
      ...prevState,
      [productId]: weight
    }));
  };

  const handleVatChange = (text) => {
    if (text === "" || !isNaN(parseFloat(text))) {
      setVatPercentage(text);
    }
  };

  const printInvoiceData = async () => {
    if (selectedItems.length === 0) {
      throw new Error("No items selected for invoice.");
    }
    try {
      const vatRate = parseFloat(vatPercentage) / 100;
  
      const totalIncludingOriginalVAT = selectedItems.reduce((acc, item) => acc + parseFloat(item.price), 0);
      const totalExcludingOriginalVAT = totalIncludingOriginalVAT / 1.21;
      const newVATAmount = totalExcludingOriginalVAT * vatRate;
      const totalIncludingNewVAT = totalExcludingOriginalVAT + newVATAmount;
  
      const invoiceContent = `
        <html>
          <body>
            <div style="text-align: center;">
              <img src="${Base64ImageComponent()}" alt="Logo" style="width: 250px; height: auto;" />
            </div>
            <div style="display: flex; justify-content: flex-end; margin-top: 20px;">
              <div style="border: 1px solid black; padding: 10px; width: 45%;">
                <p style="margin:0"><strong>${customerData.first_name} ${customerData.last_name}</strong></p>
                <p style="margin:0">${customerData.billing.address_1}</p>
                <p style="margin:0">${customerData.billing.postcode}</p>
                <p style="margin:0">${customerData.billing.country}</p>
              </div>
            </div>
            <div style="display: flex; justify-content: space-between; align-items: center; margin-top: 20px; margin-bottom: 20px; width: 100%;">
              <div style="border: 1px solid black; padding: 10px; width: 45%; display: flex; align-items: center;">
                <p style="margin: 0;"><strong>Invoice No.:</strong> ${invoiceNo}</p>
              </div>
              <div>
                <p style="margin: 0;"><strong>Antwerp</strong>, ${invoiceDate.toLocaleDateString()}</p>
              </div>
            </div>
            <table style="border-collapse: collapse;width: 100%;">
              <thead>
                <tr>
                  <th style="border: 1px solid black;padding:10px">N° Jewel</th>
                  <th style="border: 1px solid black;padding:10px">Quantity</th>
                  <th style="border: 1px solid black;padding:10px">Weight Stones/ct</th>
                  <th style="border: 1px solid black;padding:10px">Weight Gold</th>
                  <th style="border: 1px solid black;padding:10px">Gold/K.</th>
                  <th style="border: 1px solid black;padding:10px">Price/Unit</th>
                  <th style="border: 1px solid black;padding:10px">Total Price</th>
                </tr>
              </thead>
              <tbody>
                ${itemsWithNewPrices.map(item => `
                  <tr>
                    <td style="border: 1px solid black;padding:10px">${item.sku}</td>
                    <td style="border: 1px solid black;padding:10px">1</td>
                    <td style="border: 1px solid black;padding:10px">${stoneWeights[item.id] !== undefined ? stoneWeights[item.id] : initialTotalCarats[item.id]}</td>
                    <td style="border: 1px solid black;padding:10px">${goldWeights[item.id] || ''}</td>
                    <td style="border: 1px solid black;padding:10px">${goldCarat}</td>
                    <td style="border: 1px solid black;padding:10px">${item.newPrice} €</td>
                    <td style="border: 1px solid black;padding:10px">${item.newPrice} €</td>
                  </tr>
                `).join('')}
              </tbody>
            </table>
            <div style="display: flex; justify-content: space-between; margin-top: 40px;margin-bottom:20px">
              <div>
                <p style="margin:0"><strong>Due Date:</strong> ${dueDate.toLocaleDateString()}</p>
                <p style="margin:0"><strong>Payment Method:</strong> ${selectedPaymentMethod}</p>
              </div>
              <div>
                <table style="border-collapse: collapse;">
                  <tbody>
                    <tr>
                      <td style="border: 1px solid black; padding: 10px;"><strong>Total VAT excl.</strong></td>
                      <td style="border: 1px solid black; padding: 10px;">${totalExcludingOriginalVAT.toFixed(2)} €</td>
                    </tr>
                    <tr>
                      <td style="border: 1px solid black; padding: 10px;"><strong>VAT ${vatPercentage}%</strong></td>
                      <td style="border: 1px solid black; padding: 10px;">${newVATAmount.toFixed(2)} €</td>
                    </tr>
                    <tr>
                      <td style="border: 1px solid black; padding: 10px;"><strong>Total VAT incl.</strong></td>
                      <td style="border: 1px solid black; padding: 10px;">${totalIncludingNewVAT.toFixed(2)} €</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div style="margin-bottom:20px">
              <div style="border: 1px solid black; padding: 10px;width:50%">
                <p style="margin:0;font-size:14px">Please pay in € only to: KBC Bank </p>
                <p style="margin:0;font-size:14px">Name: The shamrock-Orogem jewelers </p>
                <p style="margin:0;font-size:14px">IBAN: BE52737001907509 </p>
                <p style="margin:0;font-size:14px">SWIFT: KREDBEBB</p>
              </div>
            </div>
            <div style="margin-top: 40px; text-align: left;font-size:10px;max-width:70%">
              <p style="margin:0">Article 8: Jurisdiction and applicable Law</p>
              <p style="margin:0">All disputes arising from this here contract are subject to Belgian Law. The Courts of Antwerp are the forum to resolve any such dispute, notwithstanding the seller's right to designate any other jurisdiction if he so chooses.</p>
              <p style="margin:0">Note:</p>
              <p style="margin:0">*Krachtens de artikelen 2 en 39bis,1° W.BTW wordt geen Belgische BTW in rekening gebracht.</p>
              <p style="margin:0">Above mentioned goods will be considered on consignment until full receipt of the payment of this invoice.</p>
              <p style="margin:0">The diamonds herein invoiced have been purchased from legitimate sources not involved in funding conflict and in compliance with United Nations Resolutions. The seller hereby guarantees that these diamonds are conflict free, based on personal knowledge and/or written guarantees provided by the supplier of these diamonds.</p>
              <p style="margin:0">Note:</p>
              <p style="margin:0">**The diamonds herein invoiced are exclusively of natural origin and untreated based on personal knowledge and/or written guarantees provided by the supplier of these diamonds.</p>
            </div>
            <footer style="text-align: center; margin-top: 20px;font-size:12px">
              <p>The Shamrock . Vestingstraat 38 - 2018 Antwerpen - T +32 (0)3 213 27 89 - yannick@juwelenorogem.be</p>
              <p>Bank KBC 737-0019075-09 - BTW BE 436 048 553 - H.R. Antwerpen 33.99.44</p>
            </footer>
          </body>
        </html>
      `;
  
      if (Platform.OS === 'web') {
        const win = window.open();
        win.document.write(invoiceContent);
        win.print();
        win.close();
      } else {
        const { uri } = await printAsync({
          html: invoiceContent,
        });
        await printAsync({ uri });
      }
    } catch (error) {
      console.error("Error printing invoice:", error);
    }
  };
  

  return (
    <SafeAreaView style={styles.safeArea}>
      <View style={styles.container}>
        <ScrollView contentContainerStyle={styles.scrollView}>
          <View style={styles.row}>
            <Text style={[styles.columnTitle, { width: '10%' }]}>N° Jewel</Text>
            <Text style={[styles.columnTitle, { width: '10%' }]}>Quantity</Text>
            <Text style={[styles.columnTitle, { width: '10%' }]}>Weight Stones/ct</Text>
            <Text style={[styles.columnTitle, { width: '10%' }]}>Weight Gold</Text>
            <Text style={[styles.columnTitle, { width: '10%' }]}>Gold/K.</Text>
            <Text style={[styles.columnTitle, { width: '10%' }]}>Price/Unit</Text>
            <Text style={[styles.columnTitle, { width: '10%' }]}>Total Price</Text>
          </View>

          {itemsWithNewPrices.map((item, index) => (
            <View key={index} style={styles.row}>
              <Text style={styles.item}>{item.sku}</Text>
              <Text style={styles.item}>1</Text>
              <TextInput
  style={styles.input}
  placeholder="Enter Weight (ct)"
  placeholderTextColor="#888888" 
  keyboardType="numeric"
  value={stoneWeights[item.id] !== undefined ? stoneWeights[item.id] : initialTotalCarats[item.id]}
  onChangeText={text => handleStoneWeightChange(item.id, text)}
/>
              <TextInput
                style={styles.input}
                placeholder="Enter Weight (g)"
                placeholderTextColor="#888888" 
                keyboardType="numeric"
                value={goldWeights[item.id] || ''}
                onChangeText={text => handleGoldWeightChange(item.id, text)}
              />
              <TextInput
                style={styles.input}
                defaultValue="750"
                keyboardType="numeric"
                onChangeText={text => setGoldCarat(text)}
              />
              <Text style={styles.item}>{item.newPrice} €</Text>
              <Text style={styles.item}>{item.newPrice} €</Text>
            </View>
          ))}

          <View style={styles.row}>
            <Text style={styles.columnTitle}>Customer:</Text>
            <Text style={styles.customerInfo}>{customerData.first_name} {customerData.last_name}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.columnTitle}>VAT Percentage (%):</Text>
            <TextInput
              style={styles.input}
              placeholder="Enter VAT Percentage"
              keyboardType="numeric"
              value={vatPercentage}
              onChangeText={handleVatChange}
            />
          </View>
          <View style={styles.row}>
            <Text style={styles.columnTitle}>Invoice No.:</Text>
            <TextInput
              style={styles.input}
              placeholder="Enter Invoice No."
              value={invoiceNo}
              onChangeText={text => setInvoiceNo(text)}
            />
          </View>
          <View style={styles.row}>
            <Text style={styles.columnTitle}>Due Date:</Text>
            {Platform.OS === 'web' ? (
              <DatePicker
                selected={dueDate}
                onChange={date => setDueDate(date)}
                dateFormat="dd/MM/yyyy"
                className="custom-datepicker" // Custom class to style it
                calendarClassName="custom-calendar" // Custom class for the calendar
                popperPlacement="bottom-end" // Positioning the calendar
                modal="true"
              />
            ) : (
              <DateTimePicker
                value={dueDate}
                mode="date"
                display="default"
                onChange={(event, date) => setDueDate(date)}
                style={styles.input3}
              />
            )}
          </View>
          <View style={styles.row}>
            <Text style={styles.columnTitle}>Invoice Date:</Text>
            {Platform.OS === 'web' ? (
              <DatePicker
                selected={invoiceDate}
                onChange={date => setInvoiceDate(date)}
                dateFormat="dd/MM/yyyy"
                className="custom-datepicker" // Custom class to style it
                calendarClassName="custom-calendar" // Custom class for the calendar
              />
            ) : (
              <DateTimePicker
                value={invoiceDate}
                mode="date"
                display="default"
                onChange={(event, date) => setInvoiceDate(date)}
                style={styles.input3}
              />
            )}
          </View>
          <View style={styles.row}>
            <Text style={styles.columnTitle}>Payment Method:</Text>
            <Picker
              style={styles.input2}
              selectedValue={selectedPaymentMethod}
              onValueChange={(itemValue, itemIndex) => setSelectedPaymentMethod(itemValue)}
              itemStyle={{ color: 'black' }}
              mode="dropdown" // Set the mode to dropdown for web
            >
              <Picker.Item label="Cash" value="Cash" color="black" />
              <Picker.Item label="Credit Card" value="Credit Card" color="black" />
              <Picker.Item label="Bank Transfer" value="Bank Transfer" color="black" />
            </Picker>
          </View>
          <TouchableOpacity style={styles.button} onPress={printInvoiceData}>
            <Text style={styles.buttonText}>Print Invoice</Text>
          </TouchableOpacity>
        </ScrollView>
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#000',
    padding: 20
  },
  scrollView: {
    flexGrow: 1,
    paddingHorizontal: 10,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottomWidth: 1,
    borderBottomColor: 'white',
    paddingVertical: 10,
    width: '100%',
    alignItems: 'center',
  },
  columnTitle: {
    color: 'white',
    fontSize: 15,
    fontWeight: 'bold',
    width: '15%',
    alignSelf:"center"
  },
  safeArea: {
    flex: 1,
  },
  item: {
    color: 'white',
    fontSize: 16,
    width: '10%',
  },
  customerInfo: {
    color: 'white',
    fontSize: 16,
    flex: 1,
  },
  input: {
    borderWidth: 1,
    borderColor: 'white',
    color: 'white',
    padding: 5,
    width: '10%',
  },
  input2: {
    borderWidth: 1,
    borderColor: 'white',
    color: 'black',
    padding: 10,
    width: 200,
    alignSelf:"center",
    backgroundColor:"white"
  },
  input3: {
    borderWidth: 1,
    borderColor: 'white',
    color: 'white',
    alignSelf:"center",
    backgroundColor:"white"
  },
  button: {
    backgroundColor: 'white',
    alignItems: 'center',
    padding: 15,
    borderRadius: 5,
    marginTop: 20,
  },
  buttonText: {
    color: 'black',
    fontSize: 18,
  },
  '@global .custom-datepicker': {
    width: '50%',
    padding: '5px',
    color: 'white',
    backgroundColor: '#000',
    border: '1px solid white',
    borderRadius: '5px',
    zIndex: 9999,
  },
  '@global .custom-calendar': {
    position: 'absolute',
    zIndex: 9999,
    backgroundColor: '#000',
    color: 'white',
  },
});

export default MakeInvoice;
