import React, { useState } from 'react';
import { View, Text, StyleSheet, ScrollView, TouchableOpacity, TextInput, Alert } from 'react-native';
import { useNavigation } from '@react-navigation/native';

const NewCustomer = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [postcode, setPostcode] = useState('');
  const [country, setCountry] = useState('');
  const [phone, setPhone] = useState('');

  const navigation = useNavigation();

  const handleAddCustomer = async () => {
    const apiUrl = 'https://juwelenorogem.be/en/wp-json/wc/v3/customers';
    const consumerKey = process.env.EXPO_PUBLIC_CONSUMER_KEY;
    const consumerSecret = process.env.EXPO_PUBLIC_CONSUMER_SECRET;
  
    const newCustomer = {
      username: `${firstName.toLowerCase()}.${lastName.toLowerCase()}`,
      email,
      first_name: firstName,
      last_name: lastName,
      billing: {
        first_name: firstName,
        last_name: lastName,
        address_1: address,
        city,
        state,
        postcode,
        country,
        email,
        phone,
      },
      shipping: {
        first_name: firstName,
        last_name: lastName,
        address_1: address,
        city,
        state,
        postcode,
        country,
      },
    };
  
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Basic ${btoa(`${consumerKey}:${consumerSecret}`)}`,
        },
        body: JSON.stringify(newCustomer),
      });
  
      const data = await response.json();
      if (response.ok) {
        Alert.alert('Success', 'Customer added successfully!');
        navigation.navigate('Invoice Inventory', { customer: data });
      } else {
        Alert.alert('Error', 'Failed to add customer.');
      }
    } catch (error) {
      console.error('Error adding customer:', error);
      Alert.alert('Error', 'An error occurred while adding the customer.');
    }
  };
  

  return (
    <View style={styles.container}>
      <ScrollView contentContainerStyle={styles.scrollView}>
        <TextInput
          style={styles.input}
          placeholder="First Name"
          placeholderTextColor="#888"
          value={firstName}
          onChangeText={setFirstName}
        />
        <TextInput
          style={styles.input}
          placeholder="Last Name"
          placeholderTextColor="#888"
          value={lastName}
          onChangeText={setLastName}
        />
        <TextInput
          style={styles.input}
          placeholder="Email"
          placeholderTextColor="#888"
          value={email}
          onChangeText={setEmail}
          keyboardType="email-address"
        />
        <TextInput
          style={styles.input}
          placeholder="Address"
          placeholderTextColor="#888"
          value={address}
          onChangeText={setAddress}
        />
        <TextInput
          style={styles.input}
          placeholder="City"
          placeholderTextColor="#888"
          value={city}
          onChangeText={setCity}
        />
        <TextInput
          style={styles.input}
          placeholder="State"
          placeholderTextColor="#888"
          value={state}
          onChangeText={setState}
        />
        <TextInput
          style={styles.input}
          placeholder="Postcode"
          placeholderTextColor="#888"
          value={postcode}
          onChangeText={setPostcode}
        />
        <TextInput
          style={styles.input}
          placeholder="Country"
          placeholderTextColor="#888"
          value={country}
          onChangeText={setCountry}
        />
        <TextInput
          style={styles.input}
          placeholder="Phone Number"
          placeholderTextColor="#888"
          value={phone}
          onChangeText={setPhone}
          keyboardType="phone-pad"
        />
        <TouchableOpacity style={styles.button} onPress={handleAddCustomer}>
          <Text style={styles.text}>Add Customer</Text>
        </TouchableOpacity>
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#000',
  },
  scrollView: {
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
  },
  input: {
    borderWidth: 1,
    borderColor: 'white',
    color: 'white',
    padding: 10,
    marginVertical: 10,
    width: '80%',
  },
  button: {
    borderWidth: 1,
    borderColor: 'white',
    padding: 20,
    alignItems: 'center',
    marginTop: 20,
    minWidth: 270,
  },
  text: {
    color: 'white',
    fontSize: 23,
  },
});

export default NewCustomer;
