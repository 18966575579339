// HomeStack.js
import React from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import HomeScreen from './HomeScreen';
import Inventory from './Inventory';
import Selection  from './Selection';
import NewCustomer from './NewCustomer';
import NewReseller from './NewReseller';


const Stack = createNativeStackNavigator();

const HomeStack = () => {
  return (
    <Stack.Navigator>
      <Stack.Screen name="Home" component={HomeScreen} options={{ headerShown: false }} />
      <Stack.Screen name="Selection" component={Selection} options={{ headerShown: false }} />
      <Stack.Screen name="Inventory" component={Inventory} />
      <Stack.Screen name="New Customer" component={NewCustomer} />
      <Stack.Screen name="New Reseller" component={NewReseller} />

    </Stack.Navigator>
  );
};

export default HomeStack;
