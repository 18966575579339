import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, TextInput, FlatList, ActivityIndicator, TouchableOpacity, SafeAreaView, ScrollView } from 'react-native';
import { FontAwesome } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';

const proxyUrl = 'https://stripe-server-node-lbl0p.kinsta.app/proxy?url=';
const apiUrl = 'https://juwelenorogem.be/en/wp-json/wc/v3/products';
const consumerKey = process.env.EXPO_PUBLIC_CONSUMER_KEY;
const consumerSecret = process.env.EXPO_PUBLIC_CONSUMER_SECRET;

const InvoiceInventory = ({ route }) => {
  const navigation = useNavigation();

  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [selectedItems, setSelectedItems] = useState([]);
  const customer = route.params?.customer;

  const handleMakeInvoice = () => {
    navigation.navigate('Make Invoice', {
      selectedItems: selectedItems,
      customerData: customer,
    });
  };

  useEffect(() => {
    fetchAllProducts(1, []);
  }, []);

  const fetchAllProducts = async (page, allProducts, retryCount = 0) => {
    const timestamp = new Date().getTime();
    const uniqueParam = `&_unique=${Math.random()}`;
    const url = `${proxyUrl}${encodeURIComponent(`${apiUrl}?consumer_key=${consumerKey}&consumer_secret=${consumerSecret}&orderby=price&order=asc&per_page=100&page=${page}&_=${timestamp}${uniqueParam}`)}`;

    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP status ${response.status}`);
      }

      const data = await response.json();
      const newAllProducts = allProducts.concat(data);

      if (data.length === 100) { // Check if there might be more products
        fetchAllProducts(page + 1, newAllProducts);
      } else {
        setProducts(newAllProducts);
        setFilteredProducts(newAllProducts);
        setIsLoading(false);
      }
    } catch (error) {
      console.error('Error fetching products:', error);
      if (retryCount < 3) {
        setTimeout(() => fetchAllProducts(page, allProducts, retryCount + 1), 2000);
      } else {
        setIsLoading(false);
      }
    }
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
    if (query === '') {
      setFilteredProducts(products);
    } else {
      const filtered = products.filter((product) =>
        product.sku.toLowerCase().includes(query.toLowerCase()) ||
        product.name.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredProducts(filtered);
    }
  };

  const addItemToCart = (item) => {
    setSelectedItems([...selectedItems, item]);
  };

  const removeItemFromCart = (id) => {
    const updatedItems = selectedItems.filter(item => item.id !== id);
    setSelectedItems(updatedItems);
  };

  const renderItem = ({ item }) => (
    <View style={styles.productContainer}>
      <Text style={styles.productText}>{item.name}</Text>
      <Text style={styles.productText}>{item.sku}</Text>
      <Text style={styles.productText}>{item.price}</Text>
      <TouchableOpacity onPress={() => addItemToCart(item)} style={styles.addButton}>
        <FontAwesome name="plus" size={24} color="white" />
      </TouchableOpacity>
    </View>
  );

  const renderSelectedItem = ({ item, index }) => (
    <View style={styles.selectedItemContainer}>
      <View style={styles.selectedItemTextContainer}>
        <Text style={styles.selectedItemText}>{index + 1}. {item.name}</Text>
        <Text style={styles.selectedItemText}>{item.sku}</Text>
        <Text style={styles.selectedItemText}>{item.price}</Text>
      </View>
      <TouchableOpacity onPress={() => removeItemFromCart(item.id)} style={styles.removeButton}>
        <FontAwesome name="minus" size={24} color="white" />
      </TouchableOpacity>
    </View>
  );

  return (
    <SafeAreaView style={styles.safeArea}>
      <View style={styles.container}>
        <TextInput
          style={styles.input}
          placeholder="Search by SKU or name"
          placeholderTextColor="#888"
          value={searchQuery}
          onChangeText={handleSearch}
        />
        {isLoading ? (
          <ActivityIndicator size="large" color="#fff" />
        ) : (
          <View style={styles.content}>
            <FlatList
              data={filteredProducts}
              renderItem={renderItem}
              keyExtractor={(item) => item.id.toString()}
              contentContainerStyle={styles.listContainer}
              showsVerticalScrollIndicator={false}
            />
            <View style={styles.selectedItemsContainer}>
              <Text style={styles.selectedItemsTitle}>Selected Items</Text>
              <ScrollView style={styles.selectedItemsScroll}>
                {selectedItems.map((item, index) => renderSelectedItem({ item, index }))}
              </ScrollView>
              <TouchableOpacity style={styles.invoiceButton} onPress={handleMakeInvoice}>
                <Text style={styles.invoiceButtonText}>Make Invoice</Text>
              </TouchableOpacity>
            </View>
          </View>
        )}
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#000',
    padding: 20,
  },
  safeArea: {
    flex: 1,
    backgroundColor: '#000',
  },
  input: {
    borderWidth: 1,
    borderColor: 'white',
    color: 'white',
    padding: 10,
    marginBottom: 20,
  },
  content: {
    flex: 1,
    flexDirection: 'column',
  },
  listContainer: {
    flexGrow: 1,
  },
  productContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: 'white',
    padding: 10,
    marginVertical: 5,
  },
  productText: {
    color: 'white',
    fontSize: 16,
  },
  addButton: {
    backgroundColor: 'green',
    padding: 10,
    borderRadius: 5,
  },
  selectedItemsContainer: {
    marginTop: 20,
    maxHeight: '50%',
  },
  selectedItemsTitle: {
    color: 'white',
    fontSize: 18,
    marginBottom: 10,
  },
  selectedItemsScroll: {
    flexGrow: 1,
  },
  selectedItemContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: 'white',
    padding: 10,
    marginVertical: 5,
  },
  selectedItemText: {
    color: 'white',
    fontSize: 16,
  },
  invoiceButton: {
    backgroundColor: 'blue',
    padding: 15,
    alignItems: 'center',
    borderRadius: 5,
    marginTop: 20,
  },
  invoiceButtonText: {
    color: 'white',
    fontSize: 18,
  },
  removeButton: {
    backgroundColor: 'red',
    padding: 10,
    borderRadius: 5,
  },
});

export default InvoiceInventory;
