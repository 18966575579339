import React from 'react';
import { View, Text, StyleSheet, ScrollView, TouchableOpacity } from 'react-native';

const NewInvoice = ({ navigation }) => (
  <View style={styles.container}>
    <ScrollView contentContainerStyle={styles.scrollView}>
      <TouchableOpacity style={styles.button} onPress={() => navigation.navigate('Orogem Invoices')}>
        <Text style={styles.text}>Orogem Jewelers</Text>
      </TouchableOpacity>
      <TouchableOpacity style={styles.button} onPress={() => navigation.navigate('Grenick Invoices')}>
        <Text style={styles.text}>Grenick Diamonds</Text>
      </TouchableOpacity>

    </ScrollView>
  </View>
);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#000',
  },
  scrollView: {
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    borderWidth: 1,
    borderColor: 'white',
    padding: 20,
    alignItems: 'center',
    marginBottom:20,
    minWidth:270
  },
  text: {
    color: 'white',
    fontSize: 23,
  },
});

export default NewInvoice;
