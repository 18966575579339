import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, ActivityIndicator, TouchableOpacity, SafeAreaView, ScrollView } from 'react-native';
import { Agenda } from 'react-native-calendars';
import AsyncStorage from '@react-native-async-storage/async-storage';
import moment from 'moment';

const CalendarScreen = () => {
  const [eventsData, setEventsData] = useState({});
  const [selectedDate, setSelectedDate] = useState('');
  const [loading, setLoading] = useState(true);
  const [eventType, setEventType] = useState('all'); // 'all', 'attendance', 'appointment', 'task'

  useEffect(() => {
    fetchEvents();
  }, [eventType]);

  const fetchWithToken = async (url, requiresToken = true) => {
    let headers = {
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      'Pragma': 'no-cache',
      'Expires': '0',
    };

    if (requiresToken) {
      const token = await AsyncStorage.getItem('jwtToken');
      headers.Authorization = `Bearer ${token}`;
    }

    const response = await fetch(url, {
      method: 'GET',
      headers
    });

    if (response.status === 403 && requiresToken) {
      // Handle token expiration
      const newToken = await refreshToken();
      if (newToken) {
        headers.Authorization = `Bearer ${newToken}`;
        const retryResponse = await fetch(url, {
          method: 'GET',
          headers
        });
        return retryResponse;
      }
    }

    return response;
  };

  const refreshToken = async () => {
    const refreshToken = await AsyncStorage.getItem('refreshToken');
    try {
      const response = await fetch('https://juwelenorogem.be/wp-json/jwt-auth/v1/token/refresh', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${refreshToken}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to refresh token');
      }

      const data = await response.json();
      if (data && data.token) {
        await AsyncStorage.setItem('jwtToken', data.token);
        return data.token;
      }
    } catch (error) {
      console.error('Error refreshing token:', error);
      return null;
    }
  };

  const fetchEvents = async () => {
    try {
      setLoading(true);

      // Fetch events data from WordPress API
      const attendancesResponse = await fetch('https://juwelenorogem.be/wp-json/wp/v2/attendance?');
      const attendancesData = await attendancesResponse.json();

      const appointmentsResponse = await fetch('https://juwelenorogem.be/wp-json/wp/v2/appuntamenti?');
      const appointmentsData = await appointmentsResponse.json();

      const tasksResponse = await fetch('https://juwelenorogem.be/wp-json/wp/v2/tasks?');
      const tasksData = await tasksResponse.json();

      // Ensure data is an array
      const attendanceArray = Array.isArray(attendancesData) ? attendancesData : [];
      const appointmentsArray = Array.isArray(appointmentsData) ? appointmentsData : [];
      const tasksArray = Array.isArray(tasksData) ? tasksData : [];

      // Combine all events data into a single object
      const combinedEventsData = combineEventsData(attendanceArray, appointmentsArray, tasksArray, eventType);

      setEventsData(combinedEventsData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error fetching events:', error);
    }
  };

  const combineEventsData = (attendancesData, appointmentsData, tasksData, eventType) => {
    const combinedEvents = {};

    // Function to parse dates
    const parseDate = (dateStr) => {
      const parsedDate = moment(dateStr);
      return parsedDate.isValid() ? parsedDate.format('YYYY-MM-DD') : null;
    };

    // Function to add events to combinedEvents
    const addEvents = (data, eventType) => {
      data.forEach((event) => {
        const eventDates = event.acf?.attendance_date || [];
        eventDates.forEach((dateObj) => {
          const date = parseDate(dateObj.date);
          if (date) {
            if (!combinedEvents[date]) {
              combinedEvents[date] = [];
            }
            combinedEvents[date].push({
              id: event.id,
              name: event.title.rendered,
              content: event.content.rendered,
              type: eventType
            });
          }
        });
      });
    };

    // Filter events based on eventType
    switch (eventType) {
      case 'all':
        addEvents(attendancesData, 'attendance');
        addEvents(appointmentsData, 'appointment');
        addEvents(tasksData, 'task');
        break;
      case 'attendance':
        addEvents(attendancesData, 'attendance');
        break;
      case 'appointment':
        addEvents(appointmentsData, 'appointment');
        break;
      case 'task':
        addEvents(tasksData, 'task');
        break;
      default:
        break;
    }

    return combinedEvents;
  };

  const handleFilterPress = (type) => {
    setSelectedDate('');
    setEventType(type);
  };

  const renderFilters = () => {
    return (
      <View style={{ backgroundColor: '#ccc', alignItems: 'center', justifyContent: 'center' }}>
        <ScrollView
          horizontal
          showsHorizontalScrollIndicator={false}
          contentContainerStyle={styles.filterContainer}
        >
          <TouchableOpacity
            style={[styles.filterButton, eventType === 'all' && styles.selectedFilter]}
            onPress={() => handleFilterPress('all')}
          >
            <Text style={[styles.filterButtonText, eventType === 'all' && styles.selectedFilterText]}>All</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={[styles.filterButton, eventType === 'attendance' && styles.selectedFilter]}
            onPress={() => handleFilterPress('attendance')}
          >
            <Text style={[styles.filterButtonText, eventType === 'attendance' && styles.selectedFilterText]}>Attendances</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={[styles.filterButton, eventType === 'appointment' && styles.selectedFilter]}
            onPress={() => handleFilterPress('appointment')}
          >
            <Text style={[styles.filterButtonText, eventType === 'appointment' && styles.selectedFilterText]}>Appointments</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={[styles.filterButton, eventType === 'task' && styles.selectedFilter]}
            onPress={() => handleFilterPress('task')}
          >
            <Text style={[styles.filterButtonText, eventType === 'task' && styles.selectedFilterText]}>Tasks</Text>
          </TouchableOpacity>
        </ScrollView>
      </View>
    );
  };

  const renderItem = (item, index, itemsForDay) => {
    const isFirstItemOfDay = index === 0;
    const isLastItemOfDay = itemsForDay && index === itemsForDay.length - 1;

    const itemId = `${item.id}-${index}`;

    const itemStyle = [
      styles.item,
      isFirstItemOfDay && styles.firstItem,
      isLastItemOfDay && styles.lastItem
    ];

    return (
      <View style={styles.dayContainer} key={itemId}>
        <View style={itemStyle}>
          <Text style={[styles.itemText, item.content && { color: 'red' }, item.content && { fontWeight: 'bold' }]}>
            {item.name}
          </Text>
          {item.content && (
            <Text style={[styles.itemText, { color: 'red' }]}>
              {stripHtmlTags(item.content)}
            </Text>
          )}
        </View>
      </View>
    );
  };

  const stripHtmlTags = (html) => {
    const strippedHtml = html.replace(/<[^>]+>/g, '');
    return strippedHtml;
  };

  const renderEmptyDate = () => (
    <View style={styles.emptyDate}>
      <Text>No Events for this day</Text>
    </View>
  );

  const rowHasChanged = (r1, r2) => r1.name !== r2.name;

  return (
    <SafeAreaView style={styles.safeArea}>
      <View style={styles.container}>
        <Text style={styles.title}>CALENDAR</Text>
        {renderFilters()}
        {loading ? (
          <View style={styles.loadingContainer}>
            <ActivityIndicator size="large" color="#0000ff" />
          </View>
        ) : (
          <View style={styles.agendaContainer}>
            <Agenda
              items={eventsData}
              style={styles.agenda}
              selected={selectedDate}
              renderItem={(item, index, itemsForDay) => renderItem(item, index, itemsForDay)}
              renderEmptyData={renderEmptyDate}
              rowHasChanged={rowHasChanged}
              onDayPress={(day) => setSelectedDate(day.dateString)}
              refreshing={loading}
              pastScrollRange={7}
              futureScrollRange={28}
            />
          </View>
        )}
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#000',
  },
  safeArea: {
    flex: 1,
  },
  agendaContainer: {
    flex: 1,
    paddingTop: 10,
  },
  item: {
    backgroundColor: 'white',
    borderRadius: 5,
    padding: 10,
    marginRight: 10,
    marginBottom: 1,
    minHeight: 80,
    marginBottom: 10,
  },
  itemText: {
    fontSize: 16,
    color: '#000',
  },
  emptyDate: {
    backgroundColor: 'white',
    borderRadius: 5,
    padding: 10,
    marginRight: 10,
    marginTop: 17,
    height: 50,
    justifyContent: 'center',
    alignItems: 'center',
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  firstItem: {
    paddingTop: 80,
    marginTop: 30,
  },
  title: {
    fontSize: 30,
    alignSelf: "center",
    marginVertical: 20,
    color:"white"
  },
  lastItem: {
    marginBottom: 40,
  },
  day: {
    marginTop: 0,
    padding: 10,
    backgroundColor: '#f0f0f0',
    borderRadius: 5,
  },
  filterContainer: {
    justifyContent: 'space-around',
    padding: 10,
    backgroundColor: '#ccc',
  },
  filterButton: {
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: '#ccc',
    marginRight: 10,
  },
  filterButtonText: {
    fontSize: 16,
    color: '#000',
  },
  selectedFilter: {
    backgroundColor: '#ccc',
  },
  selectedFilterText: {
    color: '#fff',
  },
});

export default CalendarScreen;
