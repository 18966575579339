import React, { useState, useEffect, useMemo, useReducer } from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { createStackNavigator } from '@react-navigation/stack';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Calendar from './screens/Calendar';
import Tasks from './screens/Tasks';
import Invoice from './screens/Invoice';
import HomeStack from './screens/HomeStack';
import LoginScreen from './screens/LoginScreen';
import AuthContext from './AuthContext'; // Add this line
import NewInvoice from './screens/NewInvoice';
import OroInvoice from './screens/OroInvoice';
import GreInvoice from './screens/GreInvoice';
import NewCustomer from './screens/NewCustomer';
import ExistingCustomer from './screens/ExistingCustomer';
import InvoiceInventory from './screens/InvoiceInventory';
import MakeInvoice from './screens/MakeInvoice';
import { StatusBar } from 'react-native';
import ResellerInventory from './screens/ResellerInventory';
import ResellerInvoice from './screens/ResellerInvoice';


const Tab = createBottomTabNavigator();
const Stack = createStackNavigator();
const InvoiceStack = createStackNavigator(); // Define a new stack navigator for Invoice and NewInvoice

const MainApp = () => (
  <Tab.Navigator>
    <Tab.Screen name="OROGEM" component={HomeStack} options={{ headerShown: false }} />
    <Tab.Screen name="CALENDAR" component={Calendar} options={{ headerShown: false }} />
    <Tab.Screen name="TASKS" component={Tasks} options={{ headerShown: false }} />
    <Tab.Screen name="INVOICES" component={InvoiceStackScreen} options={{ headerShown: false }} />

  </Tab.Navigator>
);

const InvoiceStackScreen = () => (
  <InvoiceStack.Navigator>
    <InvoiceStack.Screen name="Invoice" component={Invoice} options={{ headerShown: false }}/>
    <InvoiceStack.Screen name="New Invoice" component={NewInvoice} />
    <InvoiceStack.Screen name="Orogem Invoices" component={OroInvoice} />
    <InvoiceStack.Screen name="Grenick Invoices" component={GreInvoice} />
    <InvoiceStack.Screen name="New Customer" component={NewCustomer} />

    <InvoiceStack.Screen name="Existing Customer" component={ExistingCustomer} />
    <InvoiceStack.Screen name="Invoice Inventory" component={InvoiceInventory} />
    <InvoiceStack.Screen name="Consignment" component={ResellerInventory} />
    <InvoiceStack.Screen name="Reseller Consignment" component={ResellerInvoice} />
    <InvoiceStack.Screen name="Make Invoice" component={MakeInvoice} />
  </InvoiceStack.Navigator>
);

export default function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(null);

  useEffect(() => {
    const checkLoginStatus = async () => {
      const token = await AsyncStorage.getItem('jwtToken');
      setIsLoggedIn(!!token);
    };
    checkLoginStatus();
  }, []);


  const authContext = useMemo(() => ({
    signIn: async (token) => {
      try {
        await AsyncStorage.setItem('jwtToken', token);
        setIsLoggedIn(true);
      } catch (error) {
        console.error('Error saving user token:', error);
      }
    },
    signOut: async () => {
      try {
        await AsyncStorage.removeItem('jwtToken');
        setIsLoggedIn(false);
      } catch (error) {
        console.error('Error removing user token:', error);
      }
    },
  }), []);

  if (isLoggedIn === null) {
    return null; // You can show a loading screen here
  }

  return (
    <AuthContext.Provider value={authContext}>
    <NavigationContainer>
    <StatusBar barStyle="dark-content" backgroundColor="#000" hidden="true"/>

      <Stack.Navigator screenOptions={{ headerShown: false }}>
        {isLoggedIn ? (
          <Stack.Screen name="MainApp" component={MainApp} />
        ) : (
          <Stack.Screen name="Login" component={LoginScreen} />
        )}
      </Stack.Navigator>
    </NavigationContainer>
  </AuthContext.Provider>
  );
}
