import React, { useState, useContext } from 'react';
import { View, Text, TextInput, Button, StyleSheet, Alert, Image, TouchableOpacity } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import AuthContext from '../AuthContext'; // Importiamo l'AuthContext

const LoginScreen = ({ navigation }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const { signIn } = useContext(AuthContext); // Usiamo l'AuthContext

  const handleLogin = async () => {
    try {
      const response = await fetch('https://juwelenorogem.be/wp-json/jwt-auth/v1/token', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username,
          password,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        const { token } = data;
        await AsyncStorage.setItem('jwtToken', token);
        signIn(token); // Usiamo la funzione di signIn dall'AuthContext
      } else {
        Alert.alert('Login failed', data.message || 'Invalid username or password');
      }
    } catch (error) {
      Alert.alert('Login failed', 'An error occurred. Please try again.');
    }
  };

  return (
    <View style={styles.container}>
      <Image source={require('../assets/logo.png')} style={styles.logo} />
      <Text style={styles.label}>Username</Text>
      <TextInput
        style={styles.input}
        value={username}
        onChangeText={setUsername}
      />
      <Text style={styles.label}>Password</Text>
      <TextInput
        style={styles.input}
        value={password}
        onChangeText={setPassword}
        secureTextEntry
      />
      <TouchableOpacity style={styles.button} onPress={handleLogin}>
        <Text style={styles.labelButton}>LOGIN</Text>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    padding: 20,
    backgroundColor: "black",
  },
  label: {
    fontSize: 16,
    marginBottom: 10,
    color: "white",
    alignSelf: "center",
  },
  labelButton: {
    fontSize: 16,
    color: "black",
    alignSelf: "center",
    lineHeight: 22,
  },
  input: {
    borderWidth: 1,
    borderColor: '#ccc',
    padding: 10,
    marginBottom: 20,
    borderRadius: 5,
    width: "60%",
    alignSelf: "center",
    color: "white",
  },
  logo: {
    width: 300,
    height: 200,
    resizeMode: 'contain',
    marginTop: 50,
    marginBottom: 20,
    alignSelf: "center",
  },
  button: {
    padding: 20,
    backgroundColor: "white",
    borderRadius: 15,
    alignSelf: "center",
    alignItems: "center",
    justifyContent: "center",
    minWidth: 200,
  }
});

export default LoginScreen;
