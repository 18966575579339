import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, FlatList, TouchableOpacity, SafeAreaView, ScrollView, ActivityIndicator } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useFocusEffect } from '@react-navigation/native';

const Tasks = () => {
  const [newOrdersTasks, setNewOrdersTasks] = useState([]);
  const [newJewelryTasks, setNewJewelryTasks] = useState([]);
  const [miscellaneousTasks, setMiscellaneousTasks] = useState([]);
  const [urgentTasks, setUrgentTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refreshing, setRefreshing] = useState(false);
  const [taskType, setTaskType] = useState('all'); // 'all', 'newOrders', 'newJewelry', 'miscellaneous', 'urgent'

  useFocusEffect(
    React.useCallback(() => {
      fetchTasks();
      return () => {};
    }, [taskType])
  );

  const fetchTasks = async () => {
    const timestamp = new Date().getTime();
    try {
      setLoading(true);
      const response = await fetch(`https://juwelenorogem.be/wp-json/wp/v2/tasks?status=publish&_=${timestamp}`);
      const data = await response.json();
      const publishedTasks = data.filter(task => task.status === 'publish');

      // Filter tasks by category
      const newOrders = publishedTasks.filter(task => task.taskcategory.includes(6964));
      const newJewelry = publishedTasks.filter(task => task.taskcategory.includes(6963));
      const miscellaneous = publishedTasks.filter(task => task.taskcategory.includes(6968));
      const urgent = publishedTasks.filter(task => task.taskcategory.includes(6967));

      setNewOrdersTasks(newOrders);
      setNewJewelryTasks(newJewelry);
      setMiscellaneousTasks(miscellaneous);
      setUrgentTasks(urgent);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching tasks:', error);
      setLoading(false);
    }
  };

  const stripHtmlTags = (html) => {
    const strippedHtml = html.replace(/<[^>]+>/g, '');
    return strippedHtml;
  };

  const onRefresh = async () => {
    setRefreshing(true);
    await fetchTasks();
    setRefreshing(false);
  };

  const deleteTask = async (taskId) => {
    const deleteUrl = `https://juwelenorogem.be/wp-json/wp/v2/tasks/${taskId}`;
    try {
      const token = await AsyncStorage.getItem('jwtToken');
      const response = await fetch(deleteUrl, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.ok) {
        setNewOrdersTasks(prevTasks => prevTasks.filter(task => task.id !== taskId));
        setNewJewelryTasks(prevTasks => prevTasks.filter(task => task.id !== taskId));
        setMiscellaneousTasks(prevTasks => prevTasks.filter(task => task.id !== taskId));
        setUrgentTasks(prevTasks => prevTasks.filter(task => task.id !== taskId));
      } else {
        console.error('Error deleting task:', response.statusText);
      }
    } catch (error) {
      console.error('Error deleting task:', error);
    }
  };

  const renderItem = ({ item }) => (
    <View style={styles.taskItem}>
      <View>
      <Text style={styles.taskTitle}>{item.title.rendered}</Text>
      <Text style={styles.taskDate}>Assigned Date: {formatDate(item.acf.attendance_date)}</Text>
      <Text style={styles.taskContent}>{stripHtmlTags(item.content.rendered)}</Text>
      </View>
      <View style={styles.buttonContainer}>
        <TouchableOpacity
          style={[styles.button, styles.deleteButton]}
          onPress={() => deleteTask(item.id)}
        >
          <Text style={styles.buttonText}>Delete</Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={[styles.button, styles.editButton]}
          onPress={() => console.log(`Editing task with ID ${item.id}`)}
        >
          <Text style={styles.buttonText}>Edit</Text>
        </TouchableOpacity>
      </View>
    </View>
  );

  const formatDate = (dateObj) => {
    if (!dateObj || !dateObj.length) return '';
    const dateStr = dateObj[0].date;
    const year = dateStr.substring(0, 4);
    const month = dateStr.substring(4, 6);
    const day = dateStr.substring(6, 8);
    return `${day}-${month}-${year}`;
  };

  const handleFilterPress = (type) => {
    setTaskType(type);
  };

  const renderFilters = () => {
    return (
      <View style={{ backgroundColor: '#ccc', alignItems: 'center', justifyContent: 'center' }}>
        <ScrollView
          horizontal
          showsHorizontalScrollIndicator={false}
          contentContainerStyle={styles.filterContainer}
        >
          <TouchableOpacity
            style={[styles.filterButton, taskType === 'all' && styles.selectedFilter]}
            onPress={() => handleFilterPress('all')}
          >
            <Text style={[styles.filterButtonText, taskType === 'all' && styles.selectedFilterText]}>All</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={[styles.filterButton, taskType === 'newOrders' && styles.selectedFilter]}
            onPress={() => handleFilterPress('newOrders')}
          >
            <Text style={[styles.filterButtonText, taskType === 'newOrders' && styles.selectedFilterText]}>New Orders</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={[styles.filterButton, taskType === 'newJewelry' && styles.selectedFilter]}
            onPress={() => handleFilterPress('newJewelry')}
          >
            <Text style={[styles.filterButtonText, taskType === 'newJewelry' && styles.selectedFilterText]}>New Jewelry</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={[styles.filterButton, taskType === 'miscellaneous' && styles.selectedFilter]}
            onPress={() => handleFilterPress('miscellaneous')}
          >
            <Text style={[styles.filterButtonText, taskType === 'miscellaneous' && styles.selectedFilterText]}>Miscellaneous</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={[styles.filterButton, taskType === 'urgent' && styles.selectedFilter]}
            onPress={() => handleFilterPress('urgent')}
          >
            <Text style={[styles.filterButtonText, taskType === 'urgent' && styles.selectedFilterText]}>Urgent</Text>
          </TouchableOpacity>
        </ScrollView>
      </View>
    );
  };

  const renderTaskList = () => {
    let tasks = [];
    if (taskType === 'newOrders') {
      tasks = newOrdersTasks.map(task => ({ ...task, key: `${task.id}-newOrders` }));
    } else if (taskType === 'newJewelry') {
      tasks = newJewelryTasks.map(task => ({ ...task, key: `${task.id}-newJewelry` }));
    } else if (taskType === 'miscellaneous') {
      tasks = miscellaneousTasks.map(task => ({ ...task, key: `${task.id}-miscellaneous` }));
    } else if (taskType === 'urgent') {
      tasks = urgentTasks.map(task => ({ ...task, key: `${task.id}-urgent` }));
    } else {
      tasks = [
        ...newOrdersTasks.map(task => ({ ...task, key: `${task.id}-newOrders` })),
        ...newJewelryTasks.map(task => ({ ...task, key: `${task.id}-newJewelry` })),
        ...miscellaneousTasks.map(task => ({ ...task, key: `${task.id}-miscellaneous` })),
        ...urgentTasks.map(task => ({ ...task, key: `${task.id}-urgent` }))
      ];
    }
    return (
      <FlatList
        data={tasks}
        renderItem={renderItem}
        keyExtractor={(item) => item.key.toString()}
        contentContainerStyle={styles.listContainer}
        refreshing={refreshing}
        onRefresh={onRefresh}
      />
    );
  };


  return (
    <SafeAreaView style={styles.safeArea}>
    <View style={styles.container}>
      <Text style={styles.title}>TASKS</Text>
      {renderFilters()}
      {loading ? (
        <View style={styles.loadingContainer}>
          <Text style={{ color: "white", marginBottom: 20 }}>Loading tasks...</Text>
          <ActivityIndicator size="large" color="#fff" />
        </View>
      ) : (
        renderTaskList()
      )}
    </View>
  </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#000',
  },
  safeArea: {
    flex: 1,
  },
  listContainer: {
    paddingHorizontal: 16,
    paddingTop: 16,
  },
  title: {
    fontSize: 30,
    alignSelf: "center",
    marginVertical: 20,
    color: "white"
  },
  taskItem: {
    backgroundColor: '#f0f0f0',
    borderRadius: 8,
    padding: 16,
    marginBottom: 16,
    flexDirection:"row",
    justifyContent:"space-between"
  },
  taskTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 8,
  },
  taskContent: {
    fontSize: 16,
  },
  taskDate: {
    fontSize: 14,
    marginBottom: 8,
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 8,
  },
  button: {
    paddingVertical: 8,
    paddingHorizontal: 16,
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
  },
  deleteButton: {
    backgroundColor: 'red',
  },
  editButton: {
    backgroundColor: 'blue',
  },
  buttonText: {
    color: '#fff',
  },
  filterContainer: {
    justifyContent: 'space-around',
    padding: 10,
    backgroundColor: '#ccc',
  },
  filterButton: {
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: '#ccc',
    marginRight: 10,
  },
  filterButtonText: {
    fontSize: 16,
    color: '#000',
  },
  selectedFilter: {
    backgroundColor: '#ccc',
  },
  selectedFilterText: {
    color: '#fff',
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: "black"
  },
});

export default Tasks;
