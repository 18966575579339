import React from 'react';
import { View, Text, StyleSheet, ScrollView, TouchableOpacity } from 'react-native';

const Invoice = ({ navigation }) => (
  <View style={styles.container}>
    <ScrollView contentContainerStyle={styles.scrollView}>
      <TouchableOpacity style={styles.button} onPress={() => navigation.navigate('New Invoice')}>
        <Text style={styles.text}>NEW INVOICE</Text>
      </TouchableOpacity>
      <TouchableOpacity style={styles.button} onPress={() => navigation.navigate('Existing Customer', { screenTitle: 'New Consignment' })}>
  <Text style={styles.text}>NEW CONSIGNMENT</Text>
</TouchableOpacity>

      <TouchableOpacity style={styles.button} onPress={() => navigation.navigate('Selection')}>
        <Text style={styles.text}>NEW EXPORT</Text>
      </TouchableOpacity>
    </ScrollView>
  </View>
);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#000',
  },
  scrollView: {
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    borderWidth: 1,
    borderColor: 'white',
    padding: 20,
    alignItems: 'center',
    marginBottom:20,
    minWidth:270

  },
  text: {
    color: 'white',
    fontSize: 23,
  },
});

export default Invoice;
